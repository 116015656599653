import React, { useState, useEffect, Component } from 'react';
import BurgerMenus from './BurgerMenus';
import logo from '../img/logo/logo.png';

const Header = () => {

	const [menuOpen, setMenuOpen] = useState(false)

	// const [path, setPath] = useState("")
	// useEffect(() => {
	// 	setPath(router.pathname)
	// }, [router])

	// Sticky Menu Area start
	useEffect(() => {
		window.addEventListener('scroll', sticky);
		return () => {
			window.removeEventListener('scroll', sticky);
		};
	});

	const sticky = (e) => {
		const header = document.querySelector('.header__area');
		const scrollTop = window.scrollY;
		scrollTop >= 1 ? header.classList.add('sticky') : header.classList.remove('sticky');
	};
	// Sticky Menu Area End

	return (
		<header>
				<div id="header-sticky" className="header__area header__transparent header__padding-2">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-12">
								<div className="header__left d-flex justify-content-center">
									<div className="logo">
										<a href="/">
											<a><img src={logo} alt="logo" style={{width: "200px",backgroundColor: "#01347b"}} /></a>
										</a>
									</div>
								</div>
							</div>
							<div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
								<div className="header__right d-flex justify-content-end align-items-center">
								
									<div className="header__btn header__btn-2 ml-50 d-none d-sm-block">
										<a href="/sign-up">
											<a className="e-btn bg-submit">
											Téléchargez la brochure
											</a>
										</a>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>

				<BurgerMenus menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
				<div onClick={() => setMenuOpen(false)} className={menuOpen ? "body-overlay show" : "body-overlay"}></div>

			</header>
	);
}

export default Header;