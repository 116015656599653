import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import logo1 from '../img/partenaire/logo-mississippi.png'
import logo2 from '../img/partenaire/logo-georgia.png'


class Partenaire extends Component {

    render() {

        return (
        <section className="what__area pt-40">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-12  col-xl-12  col-lg-12 mx-auto">
                     <div className="section__title-wrapper mb-60 text-center">
                        <h2 className="section__title">
                        Partenaire 
                           <span className="yellow-bg-big"> Académique
                           <img src={banneryellow} alt="img not found" />
                           </span>
                        </h2>
                        <p>Les enseignements dispensés au sein de notre école ont été préparés conjointement avec la Mississippi State University (MSU) et le Georgia Institute of Technology (GIT et GTL), deux universités américaines prestigieuses reconnues pour leurs expertises dans les domaines de l’aéronautique et les domaines connexes. Les étudiants de l’UIR peuvent effectuer une partie de leur cursus auprès de l’un de nos partenaires dans le cadre de la double diplomation.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xxl-5 offset-xl-1 col-xl-5 offset-xl-1 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo1} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6">
                     <div className="what__item transition-3 mb-30 p-relative fix">
                        <div className="what__thumb w-img">
                           <img src={logo2}alt="img not found" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Partenaire;