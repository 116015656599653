import React, { Component } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import svg1 from '../img/shape/hero/hero-1-circle.png'
import svg2 from '../img/shape/hero/hero-1-circle-2.png'
import svg3 from '../img/shape/hero/hero-1-dot-2.png'
import banneryellow from '../img/shape/BG-1.png'

class Banner extends Component {
    render() {

        return (
            <section className="hero__area hero__height hero__height-2 d-flex align-items-center header-bg p-relative fix">
            <div className="hero__shape">
               <img className="hero-1-circle" src={svg1} alt="img not found" />
               <img className="hero-1-circle-2" src={svg2} alt="img not found" />
               <img className="hero-1-dot-2" src={svg3} alt="img not found" />
            </div>
            <div className="container">
               <div className="hero__content-wrapper mt-70 pb-10">
                  <div className="row align-items-center">
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="hero__content content hero__content-2 p-relative z-index-1 text-white">
                          <h1>#BGHIT NKOUN INGÉNIEUR </h1>
                          <h3 className='title section__title'>Rejoignez l école <span className="yellow-bg-big">d'ingénierie <img src={banneryellow} alt="img not found"/></span> aérospatiale et automobile </h3>

                           <h5>Diplôme Reconnu par l'État</h5>
                        </div>
                     </div>
                     <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                        <div className="hero__thumb-wrapper mb--20">
                           <div className="hero__thumb-2 scene">
                              {/* Call To Action Form */}
                              <form action="#" className='formHeader'>
                                 <h2 className='text-center color-blue'>Candidats Bacheliers </h2>
                                       <div className='row'>
                                         <div className='col-md-6 mt-2'>
                                         <TextField id="outlined-basic" label="Nom" variant="outlined" fullWidth className='bg-input' />
                                          </div> 
                                          <div className='col-md-6 mt-2'>
                                         <TextField id="outlined-basic" label="Prénom" variant="outlined" fullWidth className='bg-input' />
                                          </div> 
                                          <div className='col-md-12 mt-2'>
                                         <TextField id="outlined-basic" label="Téléphone" variant="outlined" fullWidth className='bg-input' />
                                          </div> 
                                          <div className='col-md-12 mt-2'>
                                         <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth className='bg-input' />
                                          </div> 
                                          <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            className='bg-input'
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Année d'obtention BAC" />}
                                          />
                                          </div>
                                          <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            className='bg-input'
                                            id="combo-box-demo"
                                            options={top100Films}
                                            renderInput={(params) => <TextField {...params} label="Ville" />}
                                          />
                                          </div>
                                       <div className='col-md-12 text-center mt-2'>
                                          <button className="e-btn px-5 bg-submit mt-2"> <span></span> Je m’inscris</button>
                                       </div> 
                                       </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}
const top100Films = [];
export default Banner;