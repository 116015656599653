import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import DirecteurImage from '../img/Directeur/Directeur-auto.jpg'


class Directeur extends Component {

    render() {

        return (
        <section className="why__area pt-50 mb-40">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div className="why__content mt-40">
                        <div className="section__title-wrapper pr-0 mb-30">
                           <h2 className="section__title pb-20">MOT <span className="yellow-bg-big"> DU DIRECTEUR<img src={banneryellow} alt="img not found" /></span></h2>
                           <p>
                           Nos deux filiales Aerospace Engineering créée en 2011 et Automotive Engineering créée en 2015, constituant la School of Automotive and Aerospace Engineering (SAAE) au sein de l’Université Internationale de Rabat (UIR) pour former des ingénieurs de haut niveau afin de répondre aux besoins industriels du Maroc et contribuer à son développement économique dans les domaines de l’aéronautique, de l’espace et de l’automobile. <br />
                           Ces domaines représentent une priorité dans la politique stratégique du pays. Le programme de ces deux filiales a été élaboré en collaboration avec deux partenaires américains, la « Georgia Institute of Technology » (GIT) et la « Mississippi State University » (MSU). Ce programme de partenariat offre une double diplomation aux étudiants inscrits aux deux écoles.
                           </p>
                        </div>
                        <div className="why__btn">
                           <a href="/contact"><a className="e-btn e-btn-3 mr-30">Rejoignez-nous</a></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                     <div className="why__thumb">
                        <img src={DirecteurImage} alt="img not found" className='w-100' />
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Directeur;