import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/logo/logo.png';
import CloseIcon from '@mui/icons-material/Close';
const BurgerMenus = ({setMenuOpen, menuOpen}) => {

      const [home, setHome] = useState(false)
      const [courses, setcourses] = useState(false)
      const [blog, setBlog] = useState(false)
      const [pages, setPages] = useState(false)

    //   const router = useRouter()
    //   const [path, setPath] = useState("")
    //   useEffect(() => {
    //     setPath(router.pathname)
    //   }, [router])

      const openMobileMenu = menu => {
        
        if( menu == 'home'){
          setHome(!home)
          setcourses(false)
          setBlog(false)
          setPages(false)
        }
        else if( menu == 'courses'){
          setHome(false)
          setcourses(!courses)
          setBlog(false)
          setPages(false)
        }
        else if( menu == 'blog'){
          setHome(false)
          setcourses(false)
          setBlog(!blog)
          setPages(false)
        }
        else if( menu == 'pages'){
          setHome(false)
          setcourses(false)
          setBlog(false)
          setPages(!pages)
        }
      }; 

      return (
      <div className={menuOpen ? "sidebar__area open": "sidebar__area"}>
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
              <button className="sidebar__close-btn" id="sidebar__close-btn" onClick={() => setMenuOpen(false)}>
                <span><CloseIcon /></span>
                <span>Fermer</span>
              </button>
          </div>
          <div className="sidebar__content">
              <div className="logo mb-40">
                <a href="/"><a><img src={logo} alt="logo" style={{width:"125px",backgroundColor: "#01347b"}}/></a></a>
              </div>
              <div className="mm-menu">
              <ul>
												<li className="">
													<a href="/"><a>Objectifis</a></a>
												</li>		
												<li><a href="/contact"><a>Partenaire </a></a></li>
												<li><a href="/contact"><a>Directeur </a></a></li>
												<li><a href="/contact"><a>Chiffres </a></a></li>
											</ul>
            </div>
              {/* <div className="sidebar__cart mt-30">
                <a href="#">
                    <div className="header__cart-icon">
                      <svg viewBox="0 0 24 24">
                          <circle className="st0" cx="9" cy="21" r="1"/>
                          <circle className="st0" cx="20" cy="21" r="1"/>
                          <path className="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                      </svg>
                    </div>
                    <span className="cart-item">2</span>
                </a>
              </div> */}
          </div>
        </div>
      </div>
          
    )
}

export default BurgerMenus;