import React from 'react'
import Header from './Header'
import Banner from './Banner'
import Service from './Serivce'
import Partenaire from './Partenaire'
import Directeur from './Directeur'
import Chiffres from './Chiffres'
import Contact from './Contact'

export default function index() {
  return (
    <>
    <Header />
    <Banner />
    <Service />
    <Partenaire />
    <Directeur />
    <Chiffres />
    <Contact />
    </>
  )
}
